import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState} from 'react'
import axios from 'axios'


function App() {
  const [data, setData]=useState([])
  useEffect(()=>{
    axios.get('http://analysisapi.speedupmanagement.com/api/industries?format=json')
      .then(response => {
        console.log(response.data)
        setData(response.data)
      })
      .catch(error => {console.log(error)})
  }, [])
  console.warn(data)
  return (
    <div className="App">
      <h1>GET API</h1>
      <table border="1">
        <tr bgcolor="lightblue">
          <td>ID</td>
          <td>Name</td>
        </tr>
        {
          data.map((item)=>
            <tr>
              <td>{item.id}</td>
              <td>{item.title}</td>
            </tr>

          )
        }
      </table>
    </div>
  );
}

export default App;
